import React from 'react';
import Pulse from 'react-reveal/Pulse';

import './loader.scss';

import logo from '../../assets/images/logo_alif.svg';

function Loader() {
    return (
        <div className="Loader">
            <Pulse forever>
                <img src={logo} alt="logo" />
            </Pulse>
        </div>
    )
}

export default Loader;