import { MainNavBarActionTypes } from '../actions/mainNavBarActions';

const initialState = {
    route: 'alif'
};

const mainNavBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case MainNavBarActionTypes.CHANGE_MAIN_NAV_BAR_THEME:
            return {
                ...state,
                route: action.payload
            }
        default:
            return state;
    }
}

export default mainNavBarReducer;