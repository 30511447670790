export const MainNavBarActionTypes = {
    CHANGE_MAIN_NAV_BAR_THEME: 'CHANGE_MAIN_NAV_BAR_THEME'
};

const changeMainNavBarTheme = (route) => {
    return {
        type: MainNavBarActionTypes.CHANGE_MAIN_NAV_BAR_THEME,
        payload: route
    }
};

export default changeMainNavBarTheme;
