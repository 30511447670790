import React, { Suspense, lazy } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "./App.scss";
import Loader from "./shared/components/Loader/Loader";

import { YMInitializer } from "react-yandex-metrika";

const Intiqol = lazy(() => import("./sites/intiqol"));
const TransferToCard = lazy(() => import('./sites/transferToCard'));
const ResultOfTransfer = lazy(() => import('./sites/transferToCard/components/ResultOfTransfer/ResultOfTransfer'));

function App() {
  return (
    <HashRouter basename="/">
      <div>
        <Suspense fallback={<Loader />}>
          {/* TODO: need to add loading indicator component */}
          <Switch>
            <Route exact path="/" component={Intiqol} />
            <Route exact path="/transfer" component={TransferToCard} />
            <Route path="/transfer/result" component={ResultOfTransfer} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </div>
      <YMInitializer accounts={[50451274]} />
    </HashRouter>
  );
}

export default App;
